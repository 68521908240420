import Axios, {  AxiosInstance} from "axios";

export type Test = {
    _id: string;
    subject: string;
    description: string;
    date: number;
}

let Http: AxiosInstance;

createClient(localStorage.getItem("token") ?? "");

export function createClient(token: string){
    Http = Axios.create({
        baseURL: "https://hcd.jan0660.dev",
        // baseURL: "http://localhost:8078",
// authorization header
        headers: {
            Authorization: localStorage.getItem("token") ?? ""
        }
    });
}

export function newTest(test: Test): Promise<any> {
    return Http.put("/tests/new", test);
}

export function getTests(): Promise<Test[]> {
    return Http.get("/tests").then(res => res.data);
}

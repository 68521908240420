import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import {BrowserRouter} from "react-router-dom";
import Helmet from "react-helmet";
initializeIcons();

ReactDOM.render(
    <BrowserRouter>

        <Helmet htmlAttributes={{ lang : "cs-CZ" }} />
        <App />
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {Component} from "react";
import {getTests, Test} from "./Client";
import {Options} from "./NewTest";
import {GroupedList, IGroup, IGroupHeaderProps, IGroupFooterProps} from '@fluentui/react/lib/GroupedList';
import {getTheme, mergeStyleSets, IRawStyle} from '@fluentui/react/lib/Styling';
import {Label} from "@fluentui/react";


let groups: IGroup[] = [];


const theme = getTheme();
const headerAndFooterStyles: IRawStyle = {
    minWidth: 300,
    minHeight: 40,
    lineHeight: 40,
    paddingLeft: 16,
};
const classNames = mergeStyleSets({
    header: [headerAndFooterStyles, theme.fonts.xLarge],
    footer: [headerAndFooterStyles, theme.fonts.large],
    name: {
        display: 'inline-block',
        overflow: 'hidden',
        height: 24,
        cursor: 'default',
        padding: 8,
        boxSizing: 'border-box',
        verticalAlign: 'top',
        background: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        paddingLeft: 32,
    },
});

const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
    if (props) {
        const toggleCollapse = (): void => {
            props.onToggleCollapse!(props.group!);
        };
        return (
            <div className={classNames.header}>
                {new Date(parseInt(props.group!.name) * 1000).toLocaleDateString(undefined, {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'numeric',
                })}
            </div>
        );
    }

    return null;
};

const onRenderCell = (nestingDepth?: number, item?: Test, itemIndex?: number): React.ReactNode => {
    return item ? (
        <div role="row" data-selection-index={itemIndex}>
            <Label><strong>{Options[Options.findIndex(val => val.key == item.subject)]?.text ?? item.subject}</strong>: {item.description}</Label>
      {/*<span role="cell" className={classNames.name}>*/}
      {/*  :*/}
      {/*</span>*/}
        </div>
    ) : null;
};

const onRenderFooter = (props?: IGroupFooterProps): JSX.Element | null => {
    return props ? <hr/> : null;
    // return props ? <div className={classNames.footer}>This is a footer for {props.group!.name}</div> : null;
};

const groupedListProps = {
    onRenderHeader,
    onRenderFooter,
    showEmptyGroups: true,
};
let items: Test[] = [];
// const groups: IGroup[] = createGroups(4, 0, 0, 5);

// export const GroupedListCustomExample: React.FunctionComponent = () => (
//     <GroupedList items={items} onRenderCell={onRenderCell} groupProps={groupedListProps} groups={groups} />
// );


class TestsView extends Component {
    constructor() {
        super({}, {});
        this.state = {data: [], groups: []};
    }

    componentDidMount() {
        getTests()
            .then(tests => {

                tests = tests.sort((a, b) => {
                    return a.date - b.date;
                });

                items = tests;


                // get unique tests by date
                let uniqueDateTests = tests.filter((test) => {
                    if (tests.findIndex((t) => t.date === test.date) === tests.indexOf(test)) {
                        return true;
                    }
                    return false
                })


                // console.log(val)
                // console.log(tests)

                groups = [];

                uniqueDateTests.forEach((test) => {
                    console.log(test)
                    let group = {} as IGroup;

                    group.name = test.date.toString();
                    group.key = test.date.toString();
                    group.startIndex = tests.indexOf(test);
                    group.count = tests.filter((t) => t.date === test.date).length;
                    // group.isCollapsed = true;
                    // group.isShowingAll = false;
                    // group.isDropEnabled = true;
                    // group.isSelected = false;
                    // group.isSubGroup = false;
                    // group.isVisible = true;
                    // group.children = [];

                    groups.push(group);

                })

                console.log(groups)


                this.setState({data: tests, groups: groups})


            });
    }

    render() {
        let val = (this.state as any).data as Test[] ?? [];
        let gr = (this.state as any).groups as IGroup[] ?? [];

        return (
            // @ts-ignore
            <div>
                {/*<ul>*/}
                {/*    {(val).map(el => (*/}
                {/*        <li>*/}
                {/*            {Options[Options.findIndex(val => val.key == el.subject)]?.text ?? "co"}: {el.description}*/}
                {/*        </li>*/}
                {/*    ))}*/}
                {/*</ul>*/}

                {/*<GroupedListCustomExample/>*/}
                <GroupedList items={items} onRenderCell={onRenderCell} groupProps={groupedListProps} groups={gr}/>
            </div>
        );
    }
}

export default TestsView;
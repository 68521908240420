// import * as React from 'react';
import {ICalendarStrings} from '@fluentui/react';

class Fucking implements ICalendarStrings {
    days: string[] = ["Neděle", "Ponděli", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"];
    goToToday: string = "Dnes";
    months: string[] = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];
    shortDays: string[] = ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"];
    shortMonths: string[] = ["Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čer", "Srp", "Zář", "Říj", "Lis", "Pro"];
}

export const CzechCalendarStrings = new Fucking();

// export const SusCalendar: React.FunctionComponent = () => {
//     const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
//
//     return (
//
//     );
// };
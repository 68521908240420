import React from "react"
import {Calendar, DateRangeType, DayOfWeek, Label, PrimaryButton, TextField} from "@fluentui/react"
import {Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles} from '@fluentui/react/lib/Dropdown';
// import {SusCalendar} from "./SusCalendar";
import {newTest, Test} from "./Client";
import {CzechCalendarStrings} from "./SusCalendar";
import {useNavigate} from "react-router-dom";

export const Options: IDropdownOption[] = [
    // { key: 'fruitsHeader', text: 'Fruits', itemType: DropdownMenuItemType.Header },
    {key: 'm', text: 'Matika'},
    {key: 'cj', text: 'Čestina'},
    {key: 'aj', text: 'Angličtina'},
    {key: 'fy', text: 'Fyzika'},
    {key: 'ch', text: 'Chemie'},
    {key: 'bi', text: 'Biologie'},
    {key: 'inf', text: 'Informatika'},
    {key: 'ob', text: 'Občanská Výchova'},
    {key: 'd', text: 'Dějepis'},
    {key: 'z', text: 'Zeměpis'},
    // { key: 'bi', text: 'Biologie' },
    {key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider},
    {key: 'nj', text: 'Němčina'},
    {key: 'fj', text: 'Francouzština'},
    // { key: 'vegetablesHeader', text: 'Vegetables', itemType: DropdownMenuItemType.Header },
];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {width: 300},
};

export default function NewTest() {
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
    selectedDate.setMilliseconds(0);
    selectedDate.setSeconds(0);
    selectedDate.setMinutes(0);
    selectedDate.setHours(0);
    const [selectedKey, setSelectedKey] = React.useState<string | number | string[] | number[] | null>();
    const [descriptionValue, setDescriptionValue] = React.useState<string>();
    const nav = useNavigate();
    return <>
        <Label>
            Hello World

            <Dropdown
                // placeholder="Select an option"
                label="Předmět"
                options={Options}
                styles={dropdownStyles}
                defaultValue={0}
                onChanged={(e, o) => setSelectedKey(o)}
            />
            <TextField label="Popis" value={descriptionValue} onChange={(e, o) => setDescriptionValue(o)}/>
            <div style={{height: '360px'}}>
                <div>{selectedDate?.toLocaleString() || 'Not set'}</div>
                <Calendar
                    // showGoToToday
                    onSelectDate={setSelectedDate}
                    value={selectedDate}
                    strings={CzechCalendarStrings}
                    // Calendar uses English strings by default. For localized apps, you must override this prop.
                    // strings={defaultCalendarStrings}
                    dateRangeType={DateRangeType.WorkWeek}
                    highlightCurrentMonth={false}
                    highlightSelectedMonth={false}
                    isMonthPickerVisible={false}
                    showMonthPickerAsOverlay={true}
                    firstDayOfWeek={DayOfWeek.Monday}
                />
            </div>
            <PrimaryButton onClick={async () => {
                let test = {} as Test;
                // @ts-ignore
                test.subject = Options[selectedKey as number].key;
                test.description = descriptionValue as string ?? "";
                // todo: dont allow today set to current day day day day by default
                test.date = Math.floor(selectedDate.getTime() / 1000);
                await newTest(test);
                nav("/");
            }} text="Vytvořit"/>
        </Label>

    </>
}

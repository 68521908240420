import React from 'react';
import './App.css';
import {PrimaryButton, ThemeProvider} from '@fluentui/react';
import {AzureThemeDark} from '@fluentui/azure-themes';
import {Routes, Route, useNavigate} from "react-router-dom";
// import {Routes, Route, Link, useNavigate,  } from "react-router-dom";
import NewTest from './NewTest'
import Helmet from 'react-helmet';
import {createClient, getTests, Test} from "./Client";
import useAsync from "react";

import qs from "qs"
import TestsView from "./TestsView";

function getOAuthUrl(): string {
    // const clientId = "745236490673586246";
    // const redirectUrl = "https://localhost:8078/";
    // const scopes = "user-read-private user-read-email";
    // return ""
    return "https://discord.com/api/oauth2/authorize?client_id=745236490673586246&redirect_uri=https%3A%2F%2Fhcd.jan0660.dev%2Foauth2%2Fcallback&response_type=code&scope=identify" + "&prompt=none";
    // return "https://discord.com/api/oauth2/authorize?client_id=745236490673586246&redirect_uri=http%3A%2F%2Flocalhost%3A8078%2Foauth2%2Fcallback&response_type=code&scope=identify" + "&prompt=none";
}

function App() {
    return (
        <ThemeProvider theme={AzureThemeDark} className="fill-window">
            <div>
                <div className="App" lang="cs-CZ">
                    {/*<h1>Welcome to hell!</h1>*/}
                    {/*<PrimaryButton href={getOAuthUrl()}>Login</PrimaryButton>*/}
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        {/*<Route path="about" element={<About/>}/>*/}
                        <Route path="/callback" element={<OAuthCallback/>}/>
                        <Route path="/newTest" element={<NewTest/>}/>
                    </Routes>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default App;

function OAuthCallback() {
    let nav = useNavigate();
    return (
        <>
            {/*<h1>Kurva</h1>*/}
            <PrimaryButton text="OK" onClick={() => {
                let h = qs.parse(document.location.search, {ignoreQueryPrefix: true});
                localStorage.setItem("token", h.q as string);
                createClient(h.q as string)
                nav("/")
            }}/>
        </>
    );
}

function Home() {
    // let href = useHref(getOAuthUrl())
    if (localStorage.getItem("token") === null) {
        // nav(getOAuthUrl())
        // href(getOAuthUrl())
        // eslint-disable-next-line no-restricted-globals
        // history.pushState(null, "", getOAuthUrl())
        window.location.href = getOAuthUrl();

        return <></>
    }

    // let tests: Test[] = [];
    // getTests().then(h => {
    //     tests = h;
    // }).catch(e => {
    //     console.log(e);
    //     return [];
    // });

    // let testsComponent = React.lazy(() => new Promise<React.ComponentType<any>>(async resolve => {
    //         // const module = await import("./MyLazyComponent");
    //         // setTimeout(() => resolve(module), 1000);
    //         let tests = await getTests();
    //
    //     // class FuckYou extends React.Component {
    //     //     render() {
    //     //         return <h1>Hello, {this.props.data}</h1>;
    //     //     }
    //     // }
    //
    //         resolve((<>
    //             {tests.map(t => {
    //                     return <h1>{t.description ?? "h"}</h1>
    //                 }
    //             )}
    //         </>));
    //     })
    // );

    // let testsComponent = React.lazy(async () => {
    //     let tests = await getTests();
    //
    //     return <>
    //         {tests.map(t => {
    //                 return <h1>{t.description ?? "h"}</h1>
    //             }
    //         )}
    //     </>
    // })


    return (
        <>
            <Helmet>
                <title>Testy</title>
            </Helmet>
            {/*<h1>Home</h1>*/}
            <PrimaryButton text="Nový Test" onClick={() => {
                window.location.href = "/newTest"
            }}/>

            <TestsView/>
        </>)
}
